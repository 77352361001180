<template>
  <v-app class="layout-store">
    <StudioTopbar
      v-if="hasStudioAccess"
      transparent
      hide-search
    />
    <StoreTopbar
      v-else
      class="layout-store__topbar"
    />
    <v-main>
      <NuxtPage />
    </v-main>
    <StoreFooter
      v-if="!hasStudioAccess"
      class="layout-store__footer"
    />

    <NewVersionAlert />
    <Snackbar />
    <TheDialog />
  </v-app>
</template>

<script>
import { mapMutations } from '~/assets/javascript/modules/vuex';
import Snackbar from '~/components/Snackbar';
import TheDialog from '~/components/dialogs/the-dialog';
import NewVersionAlert from '~/components/NewVersionAlert';
import StudioTopbar from '~/components/topbars/studio-topbar';
import StoreTopbar from '~/components/topbars/store-topbar';
import StoreFooter from '~/components/footers/store-footer';

export default {
  name: 'Store',
  components: {
    TheDialog,
    Snackbar,
    NewVersionAlert,
    StudioTopbar,
    StoreTopbar,
    StoreFooter,
  },
  setup() {
    useWorkspace();

    return {
      ...mapMutations('environment', ['setCurrentEnvironment']),
      ...mapMutations('studio', ['setInStudio']),
      ...mapMutations('workspace', ['resetWorkspace']),
    };
  },
  computed: {
    hasStudioAccess() {
      return this.$auth.loggedIn && this.$auth.user.role === 'admin';
    },
  },
  beforeMount() {
    this.setCurrentEnvironment('appStore');

    if (this.hasStudioAccess) {
      this.setInStudio();
    }
  },
  unmounted() {
    this.resetWorkspace();
  },
};
</script>
<style lang="scss">
@use '~/assets/styles/tools/zazos-background' as bg;

.layout-store {
  @include bg.zazos-background($important: true); // v-app override
  --max-width: 1160px;
  padding-inline: 16px;
}

.layout-store__topbar {
  width: var(--max-width);
  max-width: 100%;
  margin-inline: auto;
}

.layout-store__footer {
  width: 100%;
  max-width: var(--max-width);
  margin-inline: auto;
}
</style>
