<i18n lang="yaml">
pt:
  appStore: 'Loja de Apps'
  cta: 'Agendar uma demonstração'
  use: 'Comece a usar agora'
  login: 'Entrar'
en:
  appStore: 'App Store'
  cta: 'Book a demo'
  use: 'Start using now'
  login: 'Login'
</i18n>

<template>
  <nav
    color="transparent"
    elevation="0"
    class="store-topbar"
  >
    <a
      href="https://zazos.com"
      class="mr-auto"
    >
      <img
        src="/public/zazos-text.svg"
        alt="Zazos"
      >
    </a>

    <v-icon
      class="pa-3 d-md-none"
      @click="showDrawer = !showDrawer"
    >
      fa-regular fa-bars
    </v-icon>

    <component
      :is="$vuetify.display.smAndDown ? 'v-navigation-drawer' : 'div'"
      v-model="showDrawer"
      :fixed="$vuetify.display.smAndDown"
      :class="{ 'store-topbar__nav--drawer': $vuetify.display.smAndDown }"
      right
      class="store-topbar__nav"
    >
      <router-link
        :to="localePath({ name: 'store' })"
        class="store-topbar__link "
      >
        {{ t('appStore') }}
      </router-link>
      <a
        :href="scheduleDemoLink"
        target="_blank"
        rel="noopener noreferrer"
        class="store-topbar__link"
      >
        {{ t('cta') }}
      </a>
      <router-link
        :to="localePath({ name: 'sign-up', query: { utm_source: 'header' } })"
        class="store-topbar__link"
      >
        {{ t('use') }}
      </router-link>
      <v-btn
        :to="localePath({ name: 'set-workspace' })"
        color="#FD8C41"
        rounded
        variant="outlined"
        class="store-topbar__login cursor-pointer"
      >
        {{ t('login') }}
      </v-btn>
    </component>
  </nav>
</template>

<script>
import { VNavigationDrawer } from 'vuetify/components';
import { SCHEDULE_DEMO_LINK } from '~/assets/javascript/constants';

const scheduleDemoLink = () => {
  const url = new URL(SCHEDULE_DEMO_LINK);

  url.searchParams.set('utm_source', 'app_zazos');
  url.searchParams.set('utm_medium', 'app_store_topbar');

  return url.toString();
};

export default defineComponent({
  name: 'StoreTopbar',
  components: { VNavigationDrawer },
  setup() {
    return {
      t: useI18n().t,
    };
  },
  data() {
    return {
      showDrawer: false,
      scheduleDemoLink: scheduleDemoLink(),
    };
  },
});
</script>

<style lang="scss">
.store-topbar {
  display: flex;
  align-items: center;
  padding-block: 20px;
  max-height: 96px;

  .v-toolbar__content {
    padding-inline: 0; // to replicate the zazos.com/apps topbar
  }
}

.store-topbar__nav {
  display: flex;
  align-items: center;
  gap: 30px;
}

.store-topbar__nav--drawer .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
  padding-block: 32px;
  gap: 30px;
}

.store-topbar__link {
  color: #1a1b1f !important; // a override
  letter-spacing: .2px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: rgba(26,27,31,.75) !important; // a override
  }
}

.store-topbar__login {
  font-size: 15px !important;
  letter-spacing: 2px;
  font-weight: 300;
  padding: 24px;
  border-width: 2px !important;
  text-transform: none !important;
  height: 48px !important;
  padding-inline: 24px !important;
  font-weight: 500;
  display: flex;
  align-items: center;
}
</style>
